// Action Type
export const PATIENT_DATA_LOADED = "PATIENT_DATA_LOADED";
export const PATIENT_NAME = "PATIENT_NAME";
export const PATIENT_UHID = "PATIENT_UHID";
export const PATIENT_DOB = "PATIENT_DOB";
export const PATIENT_GENDER = "PATIENT_GENDER";
export const PATIENT_FHIR_ID = "PATIENT_FHIR_ID";
export const PATIENT_BED_NUMBER = "PATIENT_BED_NUMBER";
export const PATIENT_ROOM_NUMBER = "PATIENT_ROOM_NUMBER";
export const PATIENT_WARD_NAME = "PATIENT_WARD_NAME";
export const SELECTED_PATIENT_INDEX = "SELECTED_PATIENT_INDEX";
export const SELECTED_AMBULANCE_INDEX = "SELECTED_AMBULANCE_INDEX";
export const LAYOUT = "LAYOUT";
export const BED_ID = "BED_ID";
export const BED_NUMBER = "BED_NUMBER";
export const ADMISSION_DATE = "ADMISSION_DATE";
export const MASTER_PATIENT_DETAILS = "MASTER_PATIENT_DETAILS";
export const CURRENT_LOCATION = "CURRENT_LOCATION";
export const IS_AMBULANCE_CLICKED = "IS_AMBULANCE_CLICKED";
export const LOCATIONS_LIST = "LOCATIONS_LIST";
export const ONLY_LOCATIONS = "ONLY_LOCATIONS";
export const SEARCH_QUERY = "SEARCH_QUERY";

// Action
export const setHasPatientDataLoaded = (token) => {
  return {
    type: PATIENT_DATA_LOADED,
    payload: token,
  };
};

export const setPatientName = (token) => {
  return {
    type: PATIENT_NAME,
    payload: token,
  };
};

export const setPatientUHID = (token) => {
  return {
    type: PATIENT_UHID,
    payload: token,
  };
};

export const setPatientDOB = (token) => {
  return {
    type: PATIENT_DOB,
    payload: token,
  };
};

export const setPatientGender = (token) => {
  return {
    type: PATIENT_GENDER,
    payload: token,
  };
};

export const setPatientFhirId = (token) => {
  return {
    type: PATIENT_FHIR_ID,
    payload: token,
  };
};

export const setPatientBedNumber = (token) => {
  return {
    type: PATIENT_BED_NUMBER,
    payload: token,
  };
};

export const setPatientRoomNumber = (token) => {
  return {
    type: PATIENT_ROOM_NUMBER,
    payload: token,
  };
};

export const setPatientWardName = (token) => {
  return {
    type: PATIENT_WARD_NAME,
    payload: token,
  };
};

export const setSelectedPatientIndex = (index) => {
  return {
    type: SELECTED_PATIENT_INDEX,
    payload: index,
  };
};

export const setSelectedAmbulanceIndex = (index) => {
  return {
    type: SELECTED_AMBULANCE_INDEX,
    payload: index,
  };
};

export const setLayout = (token) => {
  return {
    type: LAYOUT,
    payload: token,
  };
};

export const setBedId = (token) => {
  return {
    type: BED_ID,
    payload: token,
  };
};

export const setBedNumber = (token) => {
  return {
    type: BED_NUMBER,
    payload: token,
  };
};

export const setAdmissionDate = (token) => {
  return {
    type: ADMISSION_DATE,
    payload: token,
  };
};

export const setMasterPatientDetails = (token) => {
  return {
    type: MASTER_PATIENT_DETAILS,
    payload: token,
  };
};

export const setCurrentLocation = (token) => {
  return {
    type: CURRENT_LOCATION,
    payload: token,
  };
};

export const setisAmbulanceClicked = (isClicked) => {
  return {
    type: IS_AMBULANCE_CLICKED,
    payload: isClicked,
  };
};

export const setLocationsList = (token) => {
  return {
    type: LOCATIONS_LIST,
    payload: token,
  };
};
export const setOnlyLocations = (token) => {
  return {
    type: ONLY_LOCATIONS,
    payload: token,
  };
};

export const setSearchQuery = (token: string) => {
  return {
    type: SEARCH_QUERY,
    payload: token,
  };
};
