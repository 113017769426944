import { iPatientDemographicData } from "../interfaces";

export const DMS_API_URL = process.env.REACT_APP_DMS_API_URL;
export const CRITICAL_BEDS_ID = process.env.REACT_APP_CRITICAL_BEDS;
export const IPD_PROJECT_ID = process.env.REACT_APP_IPD_PROJECT_ID;
export const OPD_PROJECT_ID = process.env.REACT_APP_OPD_PROJECT_ID;
export const LABS_PROJECT_ID = process.env.REACT_APP_LABS_PROJECT_ID;
export const CHAT_PROJECT_ID = process.env.REACT_APP_CHAT_PROJECT_ID;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const PRORITHM_URL = process.env.REACT_APP_PRORITHM_URL;
export const PRORITHM_TOKEN_1 = process.env.REACT_APP_PRORITHM_TOKEN_1;
export const PRORITHM_TOKEN_2 = process.env.REACT_APP_PRORITHM_TOKEN_2;
export const PRORITHM_TOKEN_3 = process.env.REACT_APP_PRORITHM_TOKEN_3;
export const INTENSIVIST_DOCTOR_ID =
  process.env.REACT_APP_INTENSIVIST_DOCTOR_ID;
export const JITSI_SECRET_KEY = process.env.REACT_APP_JITSI_SECRET_KEY;
export const JITSI_APP_ID = process.env.REACT_APP_JITSI_APP_ID || "";

const downloadUrl =
  DMS_API_URL + "api/dms/v1/documents/download/public/medunited?id=";
const noPatientAvailableText: string =
  "No patients found for your search query/location.";
const naText: string = "N/A";
const actionPanel: string = "Action Panel";
const treatmentAndMedications: string = "Treatment & Medications";
const notInList = "Not in List";
const oneQuantityText = "Qty-1";
const keepEditingText: string = "Keep Editing";
const submit: string = "Submit";
const years: string = "Years";
const enterFullScreen: string = "Enter Full Screen";
const exitFullscreen: string = "Exit Full Screen";
const tests: string = "Tests";
const medications: string = "Medications";
const labSuggestionsText: string = "Treatment Suggestions";
const noMedications: string = "No Medications found";
const admDate: string = "Adm.Date";
const patientDemographicData: iPatientDemographicData = {
  name: "Name",
  uhid: "UHID",
  age: "Age",
  gender: "Gender",
  ward: "Ward Name",
  room: "Room No.",
  bed: "Bed No.",
};

const patientList = [
  {
    patientId: "patient1",
    patientName: "Patient 1",
    deviceList: ["Prorithm"],
  },
  {
    patientId: "patient2",
    patientName: "Patient 2",
    deviceList: ["Prorithm"],
  },
  {
    patientId: "patient3",
    patientName: "Patient 3",
    deviceList: ["Prorithm"],
  },
];
const ambulanceList = [
  {
    ambulanceId: "ambulance1",
    ambulanceName: "Ambulance 1",
    deviceList: ["Prorithm"],
  },
  {
    ambulanceId: "ambulance2",
    ambulanceName: "Ambulance 2",
    deviceList: ["Prorithm"],
  },
  {
    ambulanceId: "ambulance3",
    ambulanceName: "Ambulance 3",
    deviceList: ["Prorithm"],
  },
];

const CONSTANTS = {
  USER_EMAIL: "user-email",
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  USER_ID: "user-id",
  REACT_TOKEN: "react-token",
  USER_TYPE: "user-type",
};

const labReports = {
  slNo: "S No",
  labId: "Lab ID",
  requestedBy: "Requested By",
  status: "Status",
  tat: "TAT",
  view: "View",
  test: "Test",
  doctor: "Doctor",
  dateAndTime: "(Ordered) Date & Time",
  noLabOrdersFound: "No Lab Orders Found",
  reportUnavailableMessage: "Report Unavailable! Please contact administrator",
  completed: "COMPLETED",
};

const diagnosis = {
  slNo: "S No",
  view: "View",
  doctorName: "Doctor Name",
  date: "Date",
  close: "Close",
  provisionalDiagnosis: "Provisional Diagnosis",
  finalDiagnosis: "Final Diagnosis",
  differentialDiagnosis: "Differential Diagnosis",
  investigations: "Investigations/Diagnostics",
  noData: "No Data",
  selectDate: "Select Date",
  showLess: "Show Less",
  showMore: "Show more",
  intensivistCommandCenter: "Intensivist Command Centre",
};

const treatment = {
  selectDate: "Select Date",
  slNo: "S No",
  view: "View",
  doctorName: "Doctor Name",
  date: "Date",
  noData: "No Data",
  close: "Close",
  reasonReferral: "Reason Referral",
  referralRequired: "Referral Required",
  department: "Department",
  treatmentPlan: "Treatment Plan",
  nameOfDoctor: "Name Of the Doctor",
  showLess: "Show less",
  showMore: "Show more",
  treatmentSuggestions: "Treatment Suggestions",
};

const assesmentText = {
  selectDate: "Select Date",
  slNo: "S No",
  view: "Preview",
  download: "Download",
  title: "Title",
  date: "Date",
  noData: "No Data",
  close: "Close",
  reasonReferral: "Reason Referral",
  referralRequired: "Referral Required",
  department: "Department",
  treatmentPlan: "Treatment Plan",
  nameOfDoctor: "Name Of the Doctor",
  showLess: "Show less",
  showMore: "Show more",
  treatmentSuggestions: "Treatment Suggestions",
};

const labReportMedications = {
  slNo: "S No",
  drugName: "Drug Name",
  dose: "Dose",
  doseUnits: "Dose Units",
  duration: "Duration",
  frequency: "Frequency",
  route: "Route",
  startDate: "Start Date",
  endDate: "End Date",
  intervals: "Intervals",
  addInstructions: "Add. Instructions",
  actions: "Actions",
  discontinue: "Discontinue",
  discontinueConfirm: "Are you sure you want to discontinue",
  yes: "Yes",
  no: "No",
};

const medicationsTable = {
  medicineName: "Medicine Name",
  dose: "Dose",
  unit: "Unit",
  route: "Route",
  frequency: "Frequency",
  interval: "Interval",
  duration: "Duration/Units",
  totalQty: "Total Qty",
  startDate: "Start Date",
  endDate: "End Date",
  sos: "SOS/Instructions",
  action: "Action",
  order: "Order",
  searchAlternative: "Search Alternative",
};

export const unitOptions = [
  { name: "Capsule(s)", value: "capsule" },
  { name: "Tablet(s)", value: "tablet" },
  { name: "ml", value: "ml" },
  { name: "mg", value: "mg" },
  { name: "IU", value: "iu" },
  { name: "Drop", value: "drop" },
  { name: "Tablespoon", value: "tablespoon" },
  { name: "Teaspoon", value: "teaspoon" },
  { name: "Unit(s)", value: "unit" },
  { name: "Puff(s)", value: "puff" },
];

export const routeOptions = [
  { name: "Buccal", value: "buccal" },
  { name: "Epidural", value: "epidural" },
  { name: "Inhalation", value: "inhalation" },
  { name: "Infusion", value: "infusion" },
  { name: "Intra-articular", value: "intra-articular" },
  { name: "Intracardiac", value: "intracardiac" },
  { name: "Intradermal", value: "intradermal" },
  { name: "Intraocular", value: "intraocular" },
  { name: "Intraosseous", value: "intraosseous" },
  { name: "Intraperitoneal", value: "intraperitoneal" },
  { name: "Intranasal", value: "intranasal" },
  { name: "Intrathecal", value: "intrathecal" },
  { name: "Intravaginal", value: "intravaginal" },
  { name: "Intravenous", value: "intravenous" },
  { name: "Nasogastric", value: "nasogastric" },
  { name: "Nasal", value: "nasal" },
  { name: "Ophthalmic", value: "ophthalmic" },
  { name: "Oral", value: "oral" },
  { name: "Otic", value: "otic" },
  { name: "Per Rectum", value: "perrectum" },
  { name: "Per-Vaginal", value: "per-vaginal" },
  { name: "Rectal", value: "rectal" },
  { name: "Sub Lingual", value: "sublingual" },
  { name: "Subcutaneous", value: "subcutaneous" },
  { name: "Topical", value: "topical" },
  { name: "Transdermal", value: "transdermal" },
];

export const frequencyOptions = [
  { name: "Immediately", value: "Immediately" },
  { name: "Once a day", value: "Once a day" },
  { name: "Twice a day", value: "Twice a day" },
  { name: "Thrice a day", value: "Thrice a day" },
  { name: "Four times a day", value: "Four times a day" },
  { name: "Every 4 hours", value: "Every 4 hours" },
  { name: "Every 6 hours", value: "Every 6 hours" },
  { name: "On alternate days", value: "On alternate days" },
  { name: "Once a week", value: "Once a week" },
  { name: "Twice a week", value: "Twice a week" },
  { name: "Thrice a week", value: "Thrice a week" },
  { name: "Four days a week", value: "Four days a week" },
  { name: "Five days a week", value: "Five days a week" },
  { name: "Six days a week", value: "Six days a week" },
  { name: "Continuous", value: "Continuous" },
];

export const durationUnits = [
  { name: "Hour(s)", value: "hour" },
  { name: "Day(s)", value: "day" },
  { name: "Month(s)", value: "month" },
  { name: "Week(s)", value: "week" },
];

export const instructions = [
  { name: "Before meals", value: "beforemeals" },
  { name: "Empty stomach", value: "emptystomach" },
  { name: "After meals", value: "aftermeals" },
  { name: "In the morning", value: "inmorning" },
  { name: "In the evening", value: "inevening" },
  { name: "At bedtime", value: "bedtime" },
  { name: "Immediately", value: "immediately" },
  { name: "As directed", value: "directed" },
];

const treatmentsText = {
  select: "Select",
  noOptionsFound: "No Options Found",
  breakfast: "Breakfast",
  lunch: "Lunch",
  dinner: "Dinner",
  startingFrom: "Starting From",
  selectAnOption: "Select an options",
  add: "Add",
  remove: "Remove",
};

const layout = {
  selectLayout: "Select Layout",
  patientVitals: "Patient Vitals",
  patientData: "Patient Data",
  intensivists: "Intensivists",
  patientDashboard: "Patient Dashboard",
  default: "Default",
};

const ambulanceText = {
  pro: "pro",
  rithm: "RiTHM",
  heartRate: "Heart Rate",
  fromECG: "From ECG",
  bpm: "bpm",
  respiratoryRate: "Respiratory Rate",
  spo2: "SpO2",
  processingFromDevice: "Processing from device",
  skinTemp: "Skin Temp",
  bloodPressure: "Blood Pressure",
  processingFromECG: "Processing from ECG & PPG",
  systolic: "Systolic",
  diastolic: "Diastolic",
  noAmbulanceAvailableText: "No ambulances available",
};

const patientDashboard = {
  mrNumber: "MR Number",
  name: "Name",
  lastVisited: "Last Visited",
  ecg: "ECG",
  bp: "BP",
  spo2: "SpO2",
  respiratoryRate: "Respiratory Rate",
  actions: "Actions",
  loadingPatients: "No patients found for your search query/location",
  view: "View",
  selectLocation: "Select Location",
  selectWard: "Select Ward",
  wardSelection: "Please select a ward",
  patientNotFoundInPod: "Unable to open. Patient not found in POD",
  openingPatientInPod: "Opening patient in POD...",
  refreshPageMessage: "Please refresh the page to receive real-time data.",
  noWardsAvailable: "No wards available",
  critical: "critical",
};

const historyVitals = {
  ecgHeartRate: "ECG Heart Rate",
  systolicBP: "Systolic BP",
  diastolicBP: "Diastolic BP",
  spo2: "SpO2",
  respiratoryRate: "Respiratory Rate",
  eightHoursInterval: "Duration: 8Hrs, Interval: 15Mins",
};

const videoCall = {
  or: "OR",
  startVideoCall: "Start Video Call",
  pod1: "POD 1",
  pod2: "POD 2",
  conferenceRoom: "Conference Room",
  selectARoom: "Select a room",
  enterRoomPlaceholder: "Enter room name in title case",
  roomNameHelperText:
    "Use complex room names (Min length: 12) like: CommandCenterUrgentCall",
  selectExistingRoom: "Select existing room",
  selectRoom: "Select a room",
  recordingFeatureUnavailable:
    "Recording facility unavailable. You can still continue with the call.",
};

const medicationAdministration = {
  medicine: "Medicine",
  dosage: "Dosage",
  duration: "Duration(units)",
  route: "Route",
  frequency: "Frequency",
  noData: "No Medicines for the day",
};

const medicationSubmitAlert =
  "Review and confirm the details before submission";

const allocation = {
  assigned: "ASSIGNED",
  critical: "critical",
  nonCritical: "non-critical",
  occupied: "OCCUPIED",
};

const alerts = {
  alertsError:
    "Patient details not found, Please check Patient ID in Mindray device",
  higherThanLimit: "Higher than limit",
  lowerThanLimit: "Lower than limit",
  high: "High",
  low: "Low",
};

const ambulance = {
  connectionClosed: "Connection closed unexpectedly. Server timeout.",
  gaugeText: "...",
};

const historyCharts = {
  ecgHeartRate: "ECG Heart Rate",
  ecgHeartRateCode: "ecgHeartRate",
  systolicBP: "Systolic BP",
  systolicBpValue: "systolicBpValue",
  diastolicBP: "Diastolic BP",
  diastolicBpValue: "diastolicBpValue",
  spo2: "SPO2",
  spo2Value: "spo2",
  respiratoryValue: "Respiratory Value",
  respiratoryValueCode: "respiratoryValue",
  systolicBpLayout: "systolicBP",
  diastolicBpLayout: "diastolicBP",
  currentlyViewing: "Currently viewing:",
  none: "None",
};

const hospitals = {
  serachPlaceholder: "Name,ID,MR Number",
};

const layoutOptions = [
  {
    value: "none",
    label: "Select Layout",
  },
  {
    value: "patientDashboard",
    label: "Patient Dashboard",
  },
  {
    value: "vitals",
    label: "Patient Vitals",
  },
  {
    value: "patientData",
    label: "Patient Data",
  },
  {
    value: "intensivists",
    label: "Intensivists",
  },
  {
    value: "default",
    label: "Default",
  },
];

const sidebarTexts = {
  logoutConfirm: "Are you sure you want to logout?",
  currentlyLoggedIn: "Currently logged in as",
  yes: "YES",
  no: "NO",
};

const imageValidationRegex = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;

export {
  noPatientAvailableText,
  naText,
  patientDemographicData,
  actionPanel,
  treatmentAndMedications,
  notInList,
  oneQuantityText,
  keepEditingText,
  submit,
  CONSTANTS,
  years,
  enterFullScreen,
  exitFullscreen,
  tests,
  medications,
  labSuggestionsText,
  downloadUrl,
  labReports,
  noMedications,
  diagnosis,
  medicationsTable,
  treatmentsText,
  layout,
  ambulanceList,
  labReportMedications,
  ambulanceText,
  admDate,
  patientList,
  patientDashboard,
  treatment,
  imageValidationRegex,
  assesmentText,
  historyVitals,
  videoCall,
  medicationAdministration,
  allocation,
  medicationSubmitAlert,
  alerts,
  ambulance,
  historyCharts,
  hospitals,
  layoutOptions,
  sidebarTexts,
};
