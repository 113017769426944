import {
  PATIENT_DATA_LOADED,
  PATIENT_DOB,
  PATIENT_FHIR_ID,
  PATIENT_GENDER,
  PATIENT_NAME,
  PATIENT_UHID,
  PATIENT_BED_NUMBER,
  PATIENT_ROOM_NUMBER,
  PATIENT_WARD_NAME,
  LAYOUT,
  BED_ID,
  BED_NUMBER,
  ADMISSION_DATE,
  SELECTED_PATIENT_INDEX,
  MASTER_PATIENT_DETAILS,
  CURRENT_LOCATION,
  SELECTED_AMBULANCE_INDEX,
  IS_AMBULANCE_CLICKED,
  LOCATIONS_LIST,
  ONLY_LOCATIONS,
  SEARCH_QUERY,
} from "./actions";

interface PatientState {
  patient_data_loaded: boolean;
  patient_name: string;
  patient_uhid: string;
  patient_dob: string;
  patient_fhir_id: string;
  patient_gender: string;
  patient_bed_number: string;
  patient_room_number: string;
  patient_ward_name: string;
  selectedPatientIndex: number | null;
  selectedAmbulanceIndex: number | null;
  searchQuery: string;
  layout: string;
  bedId: string;
  bedNumber: string;
  admissionDate: string;
  masterPatientDetails: any[];
  currentLocation: string;
  isAmbulanceClicked: boolean;
  locationsList: any[];
  onlyLocations: string[];
}

const initialState: PatientState = {
  patient_data_loaded: false,
  patient_name: "",
  patient_uhid: "",
  patient_dob: "",
  patient_fhir_id: "",
  patient_gender: "",
  patient_bed_number: "",
  patient_room_number: "",
  patient_ward_name: "",
  selectedPatientIndex: null,
  selectedAmbulanceIndex: null,
  searchQuery: "",
  layout: "none",
  bedId: "",
  bedNumber: "",
  admissionDate: "",
  masterPatientDetails: [],
  currentLocation: "Amalapuram",
  isAmbulanceClicked: false,
  locationsList: [],
  onlyLocations: [],
};

export const loadingActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATIENT_DATA_LOADED:
      return {
        ...state,
        patient_data_loaded: action.payload,
      };
    case PATIENT_NAME:
      return {
        ...state,
        patient_name: action.payload,
      };
    case PATIENT_UHID:
      return {
        ...state,
        patient_uhid: action.payload,
      };
    case PATIENT_DOB:
      return {
        ...state,
        patient_dob: action.payload,
      };
    case PATIENT_GENDER:
      return {
        ...state,
        patient_gender: action.payload,
      };
    case PATIENT_FHIR_ID:
      return {
        ...state,
        patient_fhir_id: action.payload,
      };
    case PATIENT_BED_NUMBER:
      return {
        ...state,
        patient_bed_number: action.payload,
      };
    case PATIENT_ROOM_NUMBER:
      return {
        ...state,
        patient_room_number: action.payload,
      };
    case PATIENT_WARD_NAME:
      return {
        ...state,
        patient_ward_name: action.payload,
      };
    case SELECTED_PATIENT_INDEX:
      return {
        ...state,
        selectedPatientIndex: action.payload,
      };
    case SELECTED_AMBULANCE_INDEX:
      return {
        ...state,
        selectedAmbulanceIndex: action.payload,
      };
    case LAYOUT:
      return {
        ...state,
        layout: action.payload,
      };
    case BED_ID:
      return {
        ...state,
        bedId: action.payload,
      };
    case BED_NUMBER:
      return {
        ...state,
        bedNumber: action.payload,
      };
    case ADMISSION_DATE:
      return {
        ...state,
        admissionDate: action.payload,
      };
    case MASTER_PATIENT_DETAILS:
      return {
        ...state,
        masterPatientDetails: [...state.masterPatientDetails, action.payload],
      };
    case CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
    case IS_AMBULANCE_CLICKED:
      return {
        ...state,
        isAmbulanceClicked: action.payload,
      };
    case LOCATIONS_LIST:
      return {
        ...state,
        locationsList: action.payload,
      };
    case ONLY_LOCATIONS:
      return {
        ...state,
        onlyLocations: action.payload,
      };
    case SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    default:
      return state;
  }
};
