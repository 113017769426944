import { Suspense, lazy, useState } from "react";
import LoadingComp from "../LoadingComp/LoadingComp";
import CustomTabPanel from "./CustomTabPanel";
import StyledTabs from "./StyledTabs";
import StyledTab from "./StyledTab";
import "./PatientReports.css";

const components = [
  {
    key: "labReports",
    label: "Lab Reports",
    component: lazy(() => import("./LabReports/LabReports")),
  },
  {
    key: "medications",
    label: "Medications",
    component: lazy(() => import("./Medications/Medications")),
  },
  {
    key: "diagnosis",
    label: "Diagnosis",
    component: lazy(() => import("./Diagnosis/Diagnosis")),
  },
  {
    key: "treatmentPlan",
    label: "Treatment Plan",
    component: lazy(() => import("./TreatmentPlan/TreatmentPlan")),
  },
  {
    key: "initialAssesment",
    label: "Initial Assesment",
    component: lazy(() => import("./InitialAssesment/InitialAssesment")),
  },
  {
    key: "medicationAdministration",
    label: "Medication Administration",
    component: lazy(
      () => import("./MedicationAdministration/MedicationAdministration")
    ),
  },
];

const PatientReports = () => {
  const [reportsTabValue, setReportsTabValue] = useState<number>(0);

  const handleReportsTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setReportsTabValue(newValue);
  };

  return (
    <div id="patient-reports-container" className="patient-reports">
      <StyledTabs value={reportsTabValue} onChange={handleReportsTabChange}>
        {components?.map((tab) => (
          <StyledTab key={tab.key} label={tab.label} />
        ))}
      </StyledTabs>
      {components?.map((tab, index) => (
        <CustomTabPanel key={tab?.key} value={reportsTabValue} index={index}>
          <Suspense fallback={<LoadingComp />}>
            <tab.component />
          </Suspense>
        </CustomTabPanel>
      ))}
    </div>
  );
};

export default PatientReports;
